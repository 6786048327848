import { useEffect, useRef } from 'react';

import campfireTitle from './assets/campfire-title.svg';
import dateImg from './assets/datetime.svg';
import playerTitle from './assets/players-title.svg';
import campsTitle from './assets/camps-title.svg';
import firesImg from './assets/fires.svg';
import thfcLogo from './assets/thfc-logo.svg';
import player1 from './assets/player-1-replays.jpg';
import player2 from './assets/player-2-thiefs.jpg';
import player3 from './assets/player-3-proze.jpg';
// import player4 from './assets/player-4-cented.jpg';
// import arrow from './arrow.svg';
// import envelope from './envelope.svg';
import './App.css';

function App() {
  const topBg = useRef(null);
  const botBg = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      let limit = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
        document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
      limit -= window.innerHeight;
      const p = window.scrollY/limit;
      topBg.current.style.backgroundPositionY = (-(p)*1500)+"px";
      botBg.current.style.backgroundPositionY = ((1-p)*1000)+"px"; 
      // console.log('scroll',window.scrollY,limit);
    }
    onScroll();
    window.removeEventListener('scroll',onScroll);
    window.addEventListener('scroll',onScroll);
    return () => window.removeEventListener('scroll',onScroll);
  })

  return (
    <>
      <div className="bg-header-bg bg-no-repeat bg-cover bg-center lg:bg-fill-width lg:bg-fixed" id="tbg" ref={topBg}>
        {/* header */}
        <section className="">
          <div className="container pt-8">
            <div className="bg-hero-kids bg-no-repeat bg-contain bg-center pt-[50%] pb-[5%] lg:pt-[50vh] lg:pb-[5vh]">
              <img src={campfireTitle} alt="Campfire for Tim Hortons Camp Day" className="max-w-screen-md mx-auto" />
            </div>
            <h2 className="font-black text-xl my-8"><span className="font-light">ft.</span> REPLAYS <span className="font-light">+</span> THIEFS <span className="font-light">+</span> PROZE</h2>
            <h2><img src={dateImg} alt="2pm EST - July 13th" /></h2>
          </div>
        </section>
        {/* intro */}
        <section className="container mt-32">
          <p>At Tims Camps, campfires are very special — they have a
  magical way of bringing people together, encouraging
  conversation, singing, and for many, they are at the centre of
  healing. In certain video games, they serve a similar purpose.
  However, in both cases, flames can burn out.</p>
  <p>This Camp Day, we’ll call on top Twitch streamers to gather around virtual campfires and keep them burning. For as long as the stream runs, the players will donate* Bits they earn to Tim Hortons Foundation Camps in Canada — helping send more youth to camp along the way.</p>
        </section>
      </div>
      <div className="bg-footer-bg bg-no-repeat bg-cover bg-center lg:bg-fill-width lg:bg-fixed pb-8" id="bbg" ref={botBg}>
        {/* video */}
        <section className="container">
          <h4 className="font-normal text-base trail-line">WATCH TRAILER</h4>
          <div className="aspect-video my-8">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zK9lz8Nbrcc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            {/* <video controls className="aspect-video">
              <source src="/media/Tims_CampDay_PreLaunch_Twitch_15s_16x9_V.mp4" type="video/mp4" />
            </video> */}
          </div>
          <p className="trail-line">Hosted by Replays<br/>
Tune in at 2pm EST, July 13{/*<br/>
<a href="https://www.twitch.tv/replays/schedule" target="_blank" rel="noreferrer" className="block my-4">twitch.tv/replays</a>*/}</p>
          <a href="https://www.twitch.tv/replays/schedule?segmentID=141c6545-8644-4814-9718-e91af764b8bf" target="_blank" className="boxed">SET REMINDER</a>
        </section>
        {/* PLAYERS */}
        <section className="mt-32 mb-8 container">
          <h2 className="mb-8"><img src={playerTitle} alt="The Players"/></h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 mb-32 lg:gap-12 lg:w-9/12 lg:mx-auto">
            <div className="">
              <h3>REPLAYS</h3>
              <img src={player1} alt="Replays"/>
            </div>
            <div className="">
              <h3>THIEFS</h3>
              <img src={player2} alt="Thiefs"/>
            </div>
            <div className="">
              <h3>PROZE</h3>
              <img src={player3} alt="Proze"/>
            </div>
          </div>
        </section>
        {/* CAMPS */}
        <section className="container">
          <h2 className="mb-8"><img src={campsTitle} alt="Tims Camps"/></h2>
          <img src={firesImg} alt="fires" className="max-h-8 mx-auto mb-8" />
          <p>Tim Hortons Foundation Camps is committed to helping youth from underserved communities achieve their full potential. Tims Camps equip young people from low-income families with the skills they need to design their future. It’s a 4 year development program where they learn important life skills, centered around leadership, courage, confidence &amp; resilience.</p>
          <a href="https://timscamps.com" target="_blank" rel="noreferrer" className="mb-32 boxed">Learn More</a>
        </section>
        {/* footer */}
        <section className="container">
          <h4 className="mb-2">In support of:</h4>
          <img src={thfcLogo} alt="Tim Hortons Foundation Camps" className="max-h-12 mx-auto" />
          <p className="text-xs mt-8">*Net $ proceeds of Bits earned by the players on July 13, 2022 will be donated to the Tim Hortons&reg; Foundation Camps in Canada.</p>
        </section>
      </div>
      {/* real footer */}
      <div className="container my-8">
        <nav className="border-white border-b-2 mb-8">
          <ul id="menu-footer-menu" className="list-none mt-16 mb-8 font-medium">
            <li className=""><a href="https://timscamps.com/about-tims-camps/">About Tims Camps</a></li>
            <li className=""><a href="https://timscamps.com/what-we-do/">What We Do</a></li>
            <li className=""><a href="https://timscamps.com/tims-camps-jobs/">Tims Camps Jobs</a></li>
            <li className=""><a href="https://timscamps.com/get-involved/">Get Involved</a></li>
            <li className=""><a href="https://timscamps.com/contact/">Contact</a></li>
            <li className=""><a href="https://timscamps.com/donate/">Donate</a></li>
          </ul>
        </nav>
        <div className="grid md:grid-cols-3">
				  <div className="md:text-left">
				    <h4 className="mb-8">Home Office</h4>
            <ul className="quick-links">
              <li className="leading-8">RR#2, 264 Glen Morris Road E<br/>
              St. George, ON, N0E 1N0
              </li>
              <li>tel: 519-448-1248</li>
            </ul>
			    </div>
          <div className="md:text-center">
				    <h4 className="mb-8">Follow Us on Social Media</h4>
            <ul className="mb-12 social-links">
				      <li className=""><a href="https://www.facebook.com/pg/timhortonsfoundationcamps" className="icon-facebook" title="Follow on Facebook" target="_blank" role="button" rel="noreferrer"><span className="" aria-hidden="true">Facebook</span></a></li>
              <li className=""><a href="https://twitter.com/timscamps" className="icon-twitter" title="Follow on Twitter" target="_blank" role="button" rel="noreferrer"><span className="" aria-hidden="true">Twitter</span></a></li>
              <li className=""><a href="https://www.instagram.com/timscamps" className="icon-instagram" title="Follow on Instagram" target="_blank" role="button" rel="noreferrer"><span className="" aria-hidden="true">Instagram</span></a></li>
              <li className=""><a href="https://www.youtube.com/channel/UC6w_XiE7C1yRz8Bp0IyPqgg" className="icon-youtube-play" title="Follow on Youtube" target="_blank" role="button" rel="noreferrer"><span className="" aria-hidden="true">YouTube</span></a></li>
              <li className=""><a href="https://www.linkedin.com/company/tim-hortons-foundation-camps/about/" className="icon-linkedin" title="Follow on LinkedIn" target="_blank" role="button" rel="noreferrer"><span className="" aria-hidden="true">LinkedIn</span></a></li>
			      </ul>
            <h4 className="font-normal mb-4">Change a Life</h4>
				    <a className="boxed" href="https://timscamps.com/donate" role="button">Donate Now</a>
			    </div>
          <div className="md:text-right">
				    <h4 className="mb-8">Quick Links</h4>
            <ul className="quick-links">
              <li><a href="https://timhortons.com" target="_blank" rel="noreferrer">Tim Hortons</a></li>
              <li><a href="https://compass.timscamps.com/" target="_blank" rel="noreferrer">The Compass Camper Portal</a></li>
              <li><a href="https://timscamps.com/accessibility/" rel="noopener" title="Accessibility Plan">Accessibility Plan</a></li>
              <li><a title="Legal Info" href="https://timscamps.com/legal-info/">Legal Info</a></li>
              <li><a href="https://timscamps.com/privacy-policy/">Privacy Policy</a></li>
              <li><a title="Plan de site" href="https://campstimhortons.com/wp-sitemap.xml" rel="noopener"></a></li>
              <li><a title="Sitemap" href="https://timscamps.com/wp-sitemap.xml" rel="noopener">Sitemap</a></li>
            </ul>
			    </div>
			  </div>
        <div className="">
				  <div className="">
            <div className="">
              <p className="text-xs font-bold max-w-none">Tims Camps recognizes that our work takes place on traditional Indigenous lands across North America. We are all treaty people. Many of us have come here as settlers, immigrants, newcomers in this generation or generations past. We are mindful of broken covenants and we strive to make this right, with the land and with each other.&nbsp; As settlers, this recognition must be connected to our collective commitment; to not only acknowledge historical implications of violence and intergenerational trauma that residential schools, broken treaties and tools has had on the cultural traditions, identities and the lives of indigenous peoples.</p>
			      </div>
            <div className="">
				      <p className="text-xs">Charitable No. Canada: 11926 4885 RR0001&nbsp; |&nbsp; Charitable No. USA: (501(c)) 31-1681446<br/>Copyright @ Tim Hortons® Foundation Camps</p>
			      </div>
			    </div>
  			</div>
      </div>
    </> // end container
  );
}

export default App;
